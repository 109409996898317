import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';
import { AuthGuardService } from './core/auth-guard.service';
import { LandingComponent } from './landing/landing.component';
import { LogoutComponent } from './logout/logout.component';
import { ArticleComponent } from './article/article.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { PinChangedComponent } from './shared/components/pin-changed/pin-changed.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardedGuardService } from './core/onboarded-guard.service';
import { ContactsComponent } from './contacts/contacts.component';
import { PropertiesComponent } from "./products/components/properties/properties.component";
import { CardsComponent } from "./cards/cards.component";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: HomeComponent
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    component: HomeComponent
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'landing/:login',
    component: LandingComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'article/:slug',
    component: ArticleComponent
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [AuthGuardService, OnboardedGuardService]
  },
  {
    path: 'transactions',
    canActivate: [AuthGuardService, OnboardedGuardService],
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'contacts',
    pathMatch: 'full',
    canActivate: [AuthGuardService, OnboardedGuardService],
    component: ContactsComponent
  },
  {
    path: 'user',
    canActivate: [],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'products',
    canActivate: [AuthGuardService, OnboardedGuardService],
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'properties',
    canActivate: [AuthGuardService, OnboardedGuardService],
    component: PropertiesComponent
  },
  {
    path: 'account',
    canActivate: [AuthGuardService, OnboardedGuardService],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'cards',
    canActivate: [AuthGuardService, OnboardedGuardService],
    component: CardsComponent
  },
  {
    path: 'features',
    canActivate: [AuthGuardService, OnboardedGuardService],
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'succesfuly-pin-changed',
    component: PinChangedComponent
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuardService],
    component: OnboardingComponent
  },
  {
    path: 'direct-debit',
    canActivate: [AuthGuardService, OnboardedGuardService],
    loadChildren: () => import('./direct-debits/direct-debits.module').then(m => m.DirectDebitsModule)
  },
  {
    path: '**', redirectTo: '404'
  },
  {
    path: '404',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
