import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../shared/angular-material.module';
import {
  UserBankingAccountsListComponent
} from './components/user-banking-accounts-list/user-banking-accounts-list.component';
import { UserBankingCardsComponent } from './components/user-banking-cards/user-banking-cards.component';
import { KycProcessComponent } from '../account/kyc-process/kyc-process.component';
import { HeroFooterComponent } from '../hero-footer/hero-footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AvailableBalanceBoxComponent } from './components/available-balance-box/available-balance-box.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IbanPipe } from '../pipes/iban.pipe';
import { MaskedIbanPipe } from '../pipes/maskedIban.pipe';
import { CmsContentComponent } from '../shared/components/cms-content/cms-content.component';
import { CmsBannerComponent } from '../shared/components/cms-banner/cms-banner.component';
import { ConfirmEmailComponent } from '../account/confirm-email/confirm-email.component';
import { AddressComponent } from '../account/address/address.component';
import { PropertySettingsComponent } from '../account/property-settings/property-settings.component';
import { UserPreferencesComponent } from '../account/user-preferences/user-preferences.component';
import { IdRegistrationComponent } from '../account/id-registration/id-registration.component';
import { NieComponent } from "../account/nie/nie.component";
import { ConfirmPhoneComponent } from '../account/confirm-phone/confirm-phone.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { UserInformationComponent } from '../account/user-information/user-information.component';
import { CodeInputModule } from 'angular-code-input';
import { FormInputDirective } from '../directives/form_input';
import { MaskedDateDirective } from '../directives/masked_date';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MaskitoModule } from '@maskito/angular'
import { TwoDecimalInputDirective } from '../directives/2decimal_input';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    HomeComponent,
    UserBankingAccountsListComponent,
    UserBankingCardsComponent,
    KycProcessComponent,
    HeroFooterComponent,
    AvailableBalanceBoxComponent,
/*     CmsContentComponent,
    CmsBannerComponent, */
    ConfirmEmailComponent,
    AddressComponent,
    PropertySettingsComponent,
    UserPreferencesComponent,
    IdRegistrationComponent,
    NieComponent,
    ConfirmPhoneComponent,
    UserInformationComponent,
    IbanPipe,
    MaskedIbanPipe,
    FormInputDirective,
    MaskedDateDirective,
    TwoDecimalInputDirective
  ],
  imports: [CommonModule,
    AngularMaterialModule,
    HomeRoutingModule,
    FontAwesomeModule,
    TranslateModule,
    NgxMasonryModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    CodeInputModule,
    MatSelectCountryModule.forRoot('en'),
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PdfViewerModule,
    NgxGpAutocompleteModule,
    MaskitoModule
  ],
  providers: [
    provideNgxMask(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMapsKey,
        libraries: ['places']
      })
    },
    provideLottieOptions({
      player: () => player,
    })
  ],
  exports: [
    HeroFooterComponent,
    /* CmsContentComponent,
    CmsBannerComponent, */
    ConfirmEmailComponent,
    UserPreferencesComponent,
    AddressComponent,
    PropertySettingsComponent,
    IdRegistrationComponent,
    UserInformationComponent,
    ConfirmPhoneComponent,
    IbanPipe,
    MaskedIbanPipe,
    FormInputDirective,
    MaskedDateDirective,
    TwoDecimalInputDirective,
    CodeInputModule,
    KycProcessComponent,
    NieComponent
  ]
})
export class HomeModule {
}
