import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SiteElementService} from '../core/services/site-element.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../core/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  @ViewChild('hero', {static: false}) hero!: ElementRef;
  currentLang!: string;
  elem: any = [];
  heroUrl = '';
  constructor(private siteElementService: SiteElementService,
              private lang: TranslateService,
              private langService: LanguageService,
              private r: ActivatedRoute) {
    this.langService.getLanguage().subscribe((l) =>  {
      this.currentLang = l;
      const slug = this.r.snapshot.params['slug'];
      this.siteElementService.getElements(slug, l.toUpperCase().replace('EN', 'GB').replace('NB', 'NO')).subscribe((val) => {
        this.elem = val;
        if (this.elem[0].heroimage) {
          this.heroUrl  = environment.buenoCMSUrl + this.elem[0].heroimage.url;
        }
      });
    });
  }

  ngOnInit(): void {
    const lang = this.lang.currentLang;
    this.currentLang = this.lang.currentLang;
    const slug = this.r.snapshot.params['slug'];
    this.siteElementService.getElements(slug, lang.toUpperCase().replace('EN', 'GB').replace('NB', 'NO')).subscribe((val) => {
      this.elem = val;
      if (this.elem[0].heroimage) {
        this.heroUrl  = environment.buenoCMSUrl + this.elem[0].heroimage.url;
      }
    });
  }
}
