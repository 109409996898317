<nav class="navbar is-fixed-top has-text-white" role="navigation" aria-label="main navigation">
  <div class="container is-fluid">
    <div class="navbar-brand">
      <a class="navbar-item" routerLink="/home">
        <img alt="Logo" src="../../assets/img/Bueno-logo-White.png" sizes="height: 2.75rem">
      </a>
      <a role="button" (click)="toggleNavbar()" class="navbar-burger burger" #navBurger aria-label="menu"
        aria-expanded="false" data-target="navbar-main">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar-main" class="navbar-menu" #navMenu>
      <div class="navbar-center">
        <!-- <button *ngIf="!authenticated" [disabled]="maintenance" class="button is-size-6 is-outlined mt-2 navbar-item is-danger py-1" (click)="login()">
          <fa-icon [icon]="faKey" class="mr-1"></fa-icon>
          {{'LOGIN' | translate}}
        </button>
        <button *ngIf="!authenticated" [disabled]="maintenance" class="button is-size-6 is-outlined mt-2 navbar-item  py-1 m-2" routerLink="user/register-user">
          {{'REGISTER' | translate}}
        </button> -->
        <!-- <a *ngIf="onboarded" class="navbar-item is-size-6" routerLinkActive="is-active" routerLink="/account/postbox">
          <fa-icon [icon]="faFiles" class="mr-1"></fa-icon>
          {{'FILES' | translate}} <span class="tag is-danger is-light ml-1">{{pendingFileCounter}}</span>
        </a> -->

        <a *ngIf="onboarded" class="navbar-item is-size-6" routerLinkActive="is-active" routerLink="/home">
          <!-- <fa-icon [icon]="faHome" class="mr-1"></fa-icon> -->
          {{ 'HOME' | translate }}
        </a>
        <!-- <a *ngIf="authenticated && kycVerified" class="navbar-item" routerLink="cards" routerLinkActive="is-active">
          <fa-icon [icon]="faCreditCard" class="mr-1"></fa-icon>
          {{'CARD' | translate}}
        </a> -->
        <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/transactions" routerLinkActive="is-active">
          <!-- <fa-icon [icon]="faExchangeAlt" class="mr-1"></fa-icon> -->
          {{ 'TRANSFERS.TRANSFERS' | translate }}
        </a>
        <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/contacts" routerLinkActive="is-active">
          <!-- <fa-icon [icon]="faAddressBook" class="mr-1"></fa-icon> -->
          {{ 'ACCOUNT.CONTACTS' | translate }}
        </a>
        <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/cards" routerLinkActive="is-active">
          <!-- <fa-icon [icon]="faCreditCard" class="mr-1"></fa-icon> -->
          {{ 'CARDS' | translate }}
        </a>
        <!-- <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/properties" routerLinkActive="is-active">
           <fa-icon [icon]="faHouseUser" class="mr-1"></fa-icon>
           {{'MENU_MORE_ITEMS.MY_PROPERTY' | translate}}
         </a>-->
        <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/account" routerLinkActive="is-active">
          <!-- <fa-icon [icon]="faBars" class="mr-1"></fa-icon> -->
          {{ 'MORE' | translate }}
        </a>

        <!--<a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/features" routerLinkActive="is-active">
          <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
          {{'MENU_MORE_ITEMS.PLUS' | translate}}
        </a>-->
        <!--<div *ngIf="onboarded" class="navbar-item is-size-6 dropdown" [class.is-active]="moreMenu">
          <a class="navbar-link is-size-6" (click)="toggleMoreMenu($event)" [ngClass]="activeMoreMenu?'is-active':''">
            <fa-icon [icon]="faBars"></fa-icon>&nbsp;
            {{'MORE' | translate}}
          </a>
          <div class="navbar-dropdown dropdown-content" *ngIf="moreMenu" (mouseleave)="toggleMoreMenu($event)">
            <a class="navbar-item is-size-6" routerLink="/account">{{'ACCOUNT.ACCOUNT_LABEL' | translate}}</a>
            <a class="navbar-item is-size-6" routerLink="/account/settings">{{'SETTINGS' | translate}}</a>
            <a class="navbar-item is-size-6" routerLink="/account/refer-a-friend">{{'REFERAFRIEND' | translate}}</a>
            <a *ngIf="onboarded" class="navbar-item is-size-6" routerLink="/features" routerLinkActive="is-active">{{'MENU_MORE_ITEMS.PLUS' | translate}}</a>
          </div>
        </div>-->
      </div>
      <div class="navbar-end">
        <div class="navbar-item is-size-6 dropdown" [class.is-active]="langMenu">
          <a class="navbar-link is-size-6" (click)="toggleLangMenu($event)">
            <fa-icon [icon]="faGlobe"></fa-icon>&nbsp;
            {{ language.toUpperCase() }}
          </a>
          <div class="navbar-dropdown dropdown-content" *ngIf="langMenu" (mouseleave)="toggleLangMenu($event)">
            <a class="navbar-item is-size-6" (click)="setLanguage('EN')">English</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NO')">Norsk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('ES')">Español</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('SE')">Svenska</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('FR')">Français</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DE')">Deutsch</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DA')">Dansk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NL')">Dutch (Nederlands)</a>
          </div>
        </div>
        <a *ngIf="authenticated" class="navbar-item is-size-6" routerLink="landing" (click)="logout()">
          <p class="has-text-danger">
            <fa-stack>
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon [icon]="faSignOut" [inverse]="true" stackItemSize="1x"></fa-icon>
            </fa-stack>
          </p>
        </a>
      </div>
    </div>
  </div>
</nav>