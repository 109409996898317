<section class="hero">
  <div class="hero-body px-0 pb-0">
    <div class="container">
      <div class="modal is-active" style="position: fixed;" *ngIf="showMigrationPopup">
        <div class="modal-background"></div>
        <div class="modal-content onboarding-modal" [ngClass]="isMobile ? 'p-3' : 'p-6'"
          [ngStyle]="{'max-height': isMobile ? '98%':'','width': isMobile ? '90%':''}">
          <p class="mb-4">{{ 'SEFIDE_MIGRATION.P1' |translate }}</p>
          <p class="mb-4">{{ 'SEFIDE_MIGRATION.P2' |translate }}</p>
          <p class="mb-4">{{ 'SEFIDE_MIGRATION.P3' |translate }}</p>
          <button class="button is-primary is-medium mt-5" (click)="closeMigrationPopUp()">{{ 'ERROR_CODES.CLOSE'
            |translate }}
          </button>
        </div>
      </div>
    </div>
    <div style="width: 100%;">
      <div class="columns" [ngClass]="!isMobile? 'dashboardPadding':'no-margin'" *ngIf="!loading">
        <div class="column is-one-third is-equal-height">
          <app-available-balance-box [accounts]="accounts"></app-available-balance-box>
        </div>
        <div class="column is-two-thirds is-equal-height">
          <div class="columns is-multiline transactionHistory shadow m-auto">
            <div class="column is-full" [style.position]="emptyTransactionList? 'relative': ''">
              <div class="columns m-0 p-0 is-mobile">
                <div class="column is-half is-align-content-center m-0 p-0 mb-4	">
                  <span class="is-titlecase-menu-title">{{'ACCOUNTHISTORY' |
                    translate}}</span>
                </div>
                <div class="column is-half m-0 p-0 mb-4	">
                  <button class="button is-white has-text-weight-bold alignButtonRight" style="color: #292A49"
                    routerLink="/transactions">{{'RECENT_OPERATIONS.HEADER_BUTTON_LABEL'|translate}}</button>
                </div>
              </div>
              <div [ngClass]="{disabledDiv : emptyTransactionList}" *ngFor="let transactionList of transactionsLists">
                <div class="transactions-frame1000001789">
                  <span class="transactions-text02"><span>{{ transactionList.monthLabel | translate }}
                      {{ transactionList.year }}</span></span>
                </div>
                <div class="transactions-transactioncard division-line"
                  *ngFor="let transaction of transactionList.Transactions">
                  <div class="columns is-vcentered is-mobile">
                    <div class="column is-narrow is-mobile">
                      <div [ngSwitch]="transaction.type">
                        <div *ngSwitchCase="'DirectDebitReceived'">
                          <img src="assets/img/direct-debit.png" matTooltip="Direct Debit"
                            style="height: 2.5em; width: 2.5em" />
                        </div>
                        <div *ngSwitchDefault>
                          <div *ngIf="transaction.amount >= 0">
                            <p class="transfer-round-icon positive-transaction">
                              <fa-stack>
                                <fa-icon [icon]="faCircle" class="positive-icon-bg" stackItemSize="2x"></fa-icon>
                                <fa-icon [icon]="faUpRight" class="positive-icon" stackItemSize="1x"></fa-icon>
                              </fa-stack>
                            </p>
                          </div>
                          <div *ngIf="transaction.amount < 0">
                            <p class="transfer-round-icon negative-transaction">
                              <fa-stack>
                                <fa-icon [icon]="faCircle" class="negative-icon-bg" stackItemSize="2x"></fa-icon>
                                <fa-icon [icon]="faDownRight" class="negative-icon" stackItemSize="1x"></fa-icon>
                              </fa-stack>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-mobile">
                      <p class="mb-0">
                        {{ transaction.created_at | date : 'dd.MM h:mm a' }}
                        <b>{{
                          transaction.type === 'DirectDebitReceived' ? transaction.payerName : (transaction.amount > 0 ?
                          transaction.payerName : transaction.beneficiaryName)
                          }}</b>
                      </p>
                      <p>{{ transaction.description }}</p>
                    </div>
                    <div class="column is-mobile is-narrow has-text-weight-bold">
                      <div class="has-text-right">
                        <span [ngClass]="{
                          'positive-transaction': transaction.amount >= 0,
                          'negative-transaction': transaction.amount < 0
                        }">
                          {{ transaction.amount >= 0 ? '+' : '' }}
                          {{ (transaction.amount | number: '1.2-2')!.replaceAll(',', ' ') }}
                          <span *ngIf="transaction.currencyCode === 'EUR'">€</span>
                          <span *ngIf="transaction.currencyCode === 'USD'">$</span>
                          <span
                            *ngIf="transaction.currencyCode !== 'EUR' && transaction.currencyCode !== 'USD'">&nbsp;{{
                            transaction.currencyCode }}</span>
                        </span>
                      </div>
                      <div class="has-text-right">
                        <span class="has-text-weight-light is-size-7" *ngIf="transaction.actualBalanceAfterMovement">
                          {{ (transaction.actualBalanceAfterMovement | number: '1.2-2')!.replaceAll(',', ' ') }}
                          <span *ngIf="transaction.currencyCode === 'EUR'">€</span>
                          <span *ngIf="transaction.currencyCode === 'USD'">$</span>
                          <span
                            *ngIf="transaction.currencyCode !== 'EUR' && transaction.currencyCode !== 'USD'">&nbsp;{{
                            transaction.currencyCode }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="emptyTransactionList" class="centeredMessage">
                <span class="tag is-warning is-medium">{{ 'TRANSFERS.THERE_ARE_NO_PAYMENTS_MESSAGE'|translate }}</span>
              </div>
            </div>

            <!-- <div class="column is-full" [style.position]="emptyUpcomingPayments? 'relative': ''">
              <div class="columns m-0 p-0 mb-2">
                <div class="column is-half m-0 p-0">
                  <span class="is-titlecase-menu-title">{{'UPCOMING_PAYMENTS.TITLE' |
                    translate}}</span>
                </div>
                <div class="column is-half m-0 p-0">
                  <button class="button is-primary is-outlined is-right" [ngClass]="!isMobile? 'alignButtonRight':''"
                    routerLink="/transactions/upcoming-payments">{{'RECENT_OPERATIONS.HEADER_BUTTON_LABEL'|translate}}</button>
                </div>
              </div>
              <div class="list">
                <div [ngClass]="{disabledDiv : emptyUpcomingPayments}" class="list-item box mb-1"
                  *ngFor="let u of upComingPayments.slice(0,3)">
                  <div class="container">
                    <div class="columns">
                      <div class="column is-half">
                        <p class="mb-2 has-text-weight-bold">{{u.type}}</p>
                        <p class="has-text-weight-light">{{u.description}}</p>
                      </div>
                      <div class="column has-text-right">
                        <p class="mb-2 has-text-weight-light">
                          {{'UPCOMING_PAYMENTS.AMOUNT_DETAILS_LABEL' | translate | uppercase}}</p>
                        <p class="has-text-danger has-text-weight-bold">
                          {{-1 * u.amount | number:'1.2-2' | currency: 'EUR'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="emptyUpcomingPayments" class="centeredMessage">
                  <span
                    class="tag is-warning is-medium">{{'UPCOMING_PAYMENTS.THERE_ARE_NO_PAYMENTS_MESSAGE'|translate}}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!authenticated">
      <div class="columns is-vcentered">
        <div class="column is-half-desktop">
          <div class="message">
            <div class="message-header">
              <p>{{ 'LOGIN' | translate }}</p>
            </div>
            <div class="message-body">
              <p class="mb-5">{{ 'APPREQUIRESLOGIN' | translate }}</p>
              <button class="button" (click)="login()">{{ 'CONTINUE' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>