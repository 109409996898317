import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { gql } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class SiteElementService {
  constructor(private apollo: Apollo) { }
  getElements(code: string, culture: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: gql`{
          siteContents (where: {uniquecode_eq: "${code}", culture_eq: "${culture}"}) {
            id,
            uniquecode,
            priority,
            heading,
            intro,
            bodytext,
            menuname,
            herocontent,
            heroimage {id, name, url}
          }
        }`
      })
      .valueChanges.pipe(map((res) => (res.data as any).siteContents));
  }
}
