<div class="container">
  <div #hero class="hero is-large has-background" *ngIf="elem[0] && elem[0].heroimage">
    <app-secured-image class="hero-background" [src]="heroUrl"></app-secured-image>
    <div class="hero-body">
      <div class="container text-bg" [innerHTML]="elem[0].herocontent"></div>
    </div>
  </div>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-full">
        <p class="title mt-3" *ngIf="elem[0]">{{elem[0].heading}}</p>
      </div>
      <div class="column is-full" *ngIf="elem[0]" [innerHTML]="elem[0].bodytext">
      </div>
    </div>
  </div>
</div>
