import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TopHeaderComponent } from './top-header/top-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LandingComponent } from './landing/landing.component';
import { AuthGuardService } from './core/auth-guard.service';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { HTTPListener, HTTPStatus } from './core/progress.inteceptor';
import { ArticleComponent } from './article/article.component';
import { ByPassSecurityPipe } from './pipes/bypass-security.pipe';
import { OAuthModule } from 'angular-oauth2-oidc';
import { StatisticsComponent } from './statistics/statistics.component';
import { AngularMaterialModule } from './shared/angular-material.module';
import { PinChangedComponent } from './shared/components/pin-changed/pin-changed.component';
import { PageNotFoundComponent } from './shared/components';
import { SuccesfullPaymentComponent } from './payments/succesfull-payment/succesfull-payment.component';
import { CancelPaymentComponent } from './payments/cancel-payment/cancel-payment.component';
import { HomeModule } from './home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccesfullInvoiceComponent } from './payments/succesfull-invoice/succesfull-invoice.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { LogoutComponent } from './logout/logout.component';
import { WindowRef } from './user/components/registration/WindowRef';
import { CookieService } from 'ngx-cookie-service';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardedGuardService } from './core/onboarded-guard.service';
import { ContactsComponent } from './contacts/contacts.component';
import { SubscriptionPlansComponent } from './onboarding/subscription-plans/subscription-plans.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CardsComponent } from "./cards/cards.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    LandingComponent,
    ArticleComponent,
    ByPassSecurityPipe,
    StatisticsComponent,
    PinChangedComponent,
    PageNotFoundComponent,
    SuccesfullPaymentComponent,
    CancelPaymentComponent,
    SuccesfullInvoiceComponent,
    LogoutComponent,
    OnboardingComponent,
    ContactsComponent,
    SubscriptionPlansComponent,
    CardsComponent
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiBaseUrl],
        sendAccessToken: true
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularMaterialModule,
    HomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatPasswordStrengthModule.forRoot(),
    NgbModule
  ],
  providers: [
    WindowRef,
    AuthGuardService,
    OnboardedGuardService,
    CookieService,
    HTTPStatus,
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true },
    { provide: 'BASE_API_URL', useValue: environment.apiBaseUrl },
    { provide: Window, useValue: window },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly router: Router,) {
    //router.events.subscribe(console.log)
  }
}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n_translations/', '.json');
}
